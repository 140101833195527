import "./App.css";
import { FormSelect, Spinner, Stack } from "react-bootstrap";
import React from "react";
import axios from "axios";
import redCircle from "./assets/redCircle.png";
import blueCircle from "./assets/blueCircle.png";

//02 03 07 08 17 24 11
var myBalls = "02,03,07,08,17,24,11";
// var myBalls="06,08,17,18,28,30,05";

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

function getDayOfWeek(dateString) {
  const date = new Date(dateString);
  const daysOfWeek = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
}

function App() {
  return (
    <div className="App">
      <PrizeResult />

      {/* <Button className='m-3' onClick={logClick}>点击测试</Button> */}

      {/* <a
        href="https://beian.miit.gov.cn/"
        className="bottomA"
        target="_blank"
        rel="noopener noreferrer"
      >
        沪ICP备2021037207
      </a> */}
    </div>
  );
}

function DoubleColorBall(props) {
  var balls = props.allBalls.split(",");

  var rewardBalls = props.rewardBalls;
  //需要检查 中奖的号码的匹配程度
  if (rewardBalls) {
    var redBalls = rewardBalls.substr(0, rewardBalls.lastIndexOf(","));
    var blueBall = rewardBalls.substr(rewardBalls.lastIndexOf(",") + 1);

    return (
      <div className="RowStyle">
        <Ball
          chosen={redBalls.indexOf(balls[0]) !== -1}
          num={balls[0]}
          colorStyle="redBall"
        />
        <Ball
          chosen={redBalls.indexOf(balls[1]) !== -1}
          num={balls[1]}
          colorStyle="redBall"
        />
        <Ball
          chosen={redBalls.indexOf(balls[2]) !== -1}
          num={balls[2]}
          colorStyle="redBall"
        />
        <Ball
          chosen={redBalls.indexOf(balls[3]) !== -1}
          num={balls[3]}
          colorStyle="redBall"
        />
        <Ball
          chosen={redBalls.indexOf(balls[4]) !== -1}
          num={balls[4]}
          colorStyle="redBall"
        />
        <Ball
          chosen={redBalls.indexOf(balls[5]) !== -1}
          num={balls[5]}
          colorStyle="redBall"
        />
        <Ball
          chosen={blueBall === balls[6]}
          num={balls[6]}
          colorStyle="blueBall"
        />
      </div>
    );
  }

  return (
    <div className="RowStyle">
      <Ball num={balls[0]} colorStyle="redBall" />
      <Ball num={balls[1]} colorStyle="redBall" />
      <Ball num={balls[2]} colorStyle="redBall" />
      <Ball num={balls[3]} colorStyle="redBall" />
      <Ball num={balls[4]} colorStyle="redBall" />
      <Ball num={balls[5]} colorStyle="redBall" />
      <Ball num={balls[6]} colorStyle="blueBall" />
    </div>
  );
}

function Ball(props) {
  //是否中奖
  var chosen = props.chosen;
  var num = props.num;
  var colorStyle = props.colorStyle;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          width: "42px",
          height: "48px",
          backgroundImage: `url(${
            colorStyle === "redBall" ? redCircle : blueCircle
          })`,
          backgroundSize: "cover", // 确保图片覆盖整个容器
          backgroundPosition: "center", // 居中显示图片
        }}
      >
        <div className="lotteryNum">{num}</div>
      </div>

      {chosen && <div className="circle"></div>}
    </div>
  );
}

class PrizeResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataResult: null, selectedDate: null };
  }

  componentDidMount() {
    document.title = "开奖结果查询";
    var url = "https://bill.raybuze.com/user/queryLottery";

    axios
      .post(url, {
        pageNo: 1,
        pageSize: 10,
      })
      .then((result) => {
        var resultData = result.data;
        if (resultData.status === 1) {
          this.setState({ dataResult: resultData.data });
        }
      })
      .catch(function (e) {
        console.log("error:" + e);
      });
  }

  componentWillUnmount() {}

  render() {
    if (this.state.dataResult === null) {
      return <Loading />;
    }

    var resultList = this.state.dataResult;
    // console.log("resultList:", resultList);

    // 选择的期号
    var chosenCode = this.state.selectedDate;
    if (chosenCode === null) {
      chosenCode = resultList[0].code;
    }

    var currentBallData = resultList.find(
      (value, _) => value.code === chosenCode
    );
    // console.log("lastBallData", currentBallData);

    var rBalls = currentBallData["balls"];

    var mBallList = myBalls.split(",");
    var myRedBalls = mBallList.slice(0, 6);
    var myBlueBall = mBallList.slice(6, 7)[0];

    var rBallList = rBalls.split(",");
    var rRedBalls = rBallList.slice(0, 6);
    var rBlueBall = rBallList.slice(6, 7)[0];
    var rRedNum = 0;
    var rBlueNum = 0;
    if (myBlueBall === rBlueBall) {
      rBlueNum = 1;
    }

    for (var b of myRedBalls) {
      if (rRedBalls.indexOf(b) !== -1) {
        rRedNum += 1;
      }
    }

    // 计算中奖情况
    var rewardResult;
    if (rRedNum === 6 && rBlueNum === 1) {
      rewardResult = "一等奖";
    } else if (rRedNum === 6 && rBlueNum === 0) {
      rewardResult = "二等奖";
    } else if (rRedNum === 5 && rBlueNum === 1) {
      rewardResult = "三等奖";
    } else if (
      (rRedNum === 5 && rBlueNum === 0) ||
      (rRedNum === 4 && rBlueNum === 1)
    ) {
      rewardResult = "四等奖";
    } else if (
      (rRedNum === 4 && rBlueNum === 0) ||
      (rRedNum === 3 && rBlueNum === 1)
    ) {
      rewardResult = "五等奖";
    } else if (rBlueNum === 1) {
      rewardResult = "六等奖";
    }

    var handleChange = (event) => {
      this.setState({ selectedDate: event.target.value });
    };

    const options = resultList.map((data) => (
      <option key={data.code} value={data.code}>
        {data.code}
      </option>
    ));

    var prizeContent = (
      <Stack>
        <Stack direction="horizontal">
          <label htmlFor="options">开奖期号：</label>
          <FormSelect
            onChange={handleChange}
            aria-label="Default select example"
            size="sm"
            style={{ width: "200px", height: "35px" }}
          >
            {options}
          </FormSelect>
        </Stack>
        <div style={{ marginTop: "5px" }}>
          开奖日期：{currentBallData.date}（{getDayOfWeek(currentBallData.date)}
          ）
        </div>
      </Stack>
    );

    var rewardContent;
    if (rewardResult) {
      rewardContent = (
        <Stack>
          <div
            style={{ fontSize: "24px", color: "red", textAlign: "center" }}
            className="my-3"
          >
            恭喜您中了{rewardResult}!
          </div>
        </Stack>
      );
    } else {
      rewardContent = (
        <div
          style={{ fontSize: "24px", color: "gray", textAlign: "center" }}
          className="my-3"
        >
          还没到中的时候~
        </div>
      );
    }

    return (
      <Stack className="m-3">
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>您的号码为：</div>
        <div style={{ height: "80px", marginTop: "20px" }}>
          <DoubleColorBall allBalls={myBalls} rewardBalls={rBalls} />
        </div>
        <Stack>
          {prizeContent}
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            开奖号码为：
          </div>
          <DoubleColorBall allBalls={rBalls} />
          <div style={{ marginTop: "20px" }}>红球中奖数：{rRedNum}</div>
          <div>蓝球中奖数：{rBlueNum}</div>
        </Stack>
        {rewardContent}
      </Stack>
    );
  }
}

export default App;
